.home-container {
  /* background-color: red; */
}

.crop-card {
  display: flex;
  height: 100vh;
  flex-direction: column;

  /* background-color: red; */
}

.link-info {
  display: flex;
  justify-content: space-evenly;
  /* background-color: blue; */
}
