.lineselection__header {
  display: flex;
  justify-content: space-between;
}

.lineselection__header h1 {
  font-family: sans-serif;
}

.lineselection__header__button {
  display: flex;
  align-items: center;
  border: none;
  background: #4e73df;
  color: white;
  border-radius: 5px;
  height: 40px;
  font-family: sans-serif;
}

.lineselection__header__button:hover {
  color: white;
}

.lineselection__header__button span {
  padding: 5px;
}
