.linedetails__container {
  display: flex;
  padding: 30px;
  /* background-color: green; */
}

.linedetails__leftside_context {
  /* background-color: red; */
  display: flex;
  /* width: 50%; */
}
.linedetail__leftside__subimages {
  /* background-color: blue; */
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 75vh;
  overflow-y: scroll;
  /* padding: 5px; */
}
.linedetail__img {
  width: 200px;
  height: 120px;
  cursor: pointer;
  margin-top: 10px;
}
.linedetails__leftside_context img {
  border: none;
  border-radius: 10px;
  height: 75vh;
  width: 29vw;
}

.linedetails__rightside_context {
  width: 40vw;
  /* background-color: blue; */
  margin-left: 60px;
}
.linedetails__rightside_context h3 {
  margin-bottom: 23px;
}
.linedetails__rightside_context span {
  font-family: sans-serif;
  text-align: left;
}

.linedetail__rightside__attributes__context {
  margin-top: 35px;
}

.linedetail__rightside__attributes__context h4 {
  font-family: sans-serif;
}

.linedetail__rightside__trait__context {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linedetail__button {
  width: 100%;
  height: 42px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 30px;
}

/* .active__linedetail {
  width: 120px;
  height: 100px;
  background-color: green;
  border: 2px solid #007bff;
} */

@media (max-width: 1024px) {
  .linedetails__container {
    display: flex;
    flex-direction: column;
  }

  .linedetails__leftside_context {
    display: flex;
    flex-direction: column;
  }

  .linedetails__leftside_context img {
    width: 100%;
    height: 450px;
  }

  .linedetail__leftside__subimages {
    display: flex;
    flex-direction: row;
    height: 170px;
    overflow-x: scroll;
    margin-left: 0;
  }

  .linedetail__img {
    width: 200px;
    height: 120px;
    cursor: pointer;
    margin-left: 10px;
  }

  .linedetails__rightside_context {
    margin: 0;
    width: 100%;
  }
}
