/* Login.css */
.login-container {
  /* background: linear-gradient(135deg, #74b9ff, #0984e3); */
  display: inline-block; /* Use inline-block to center the container */
  padding: 15%; /* Add some padding for spacing */
}
.center-container {
  width: 100%;
  text-align: center;
}

.login-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 500px;
  text-align: center;
}

.logo-img {
  width: 170px;
  margin-bottom: 2px;
}

.login-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.login-form {
  text-align: left;
}

.login-form .form-group {
  margin-bottom: 20px;
}

.login-form label {
  font-weight: bold;
}

.login-button {
  background-color: #0984e3;
  color: #fff;
  font-weight: bold;
  border: none;
  transition: background-color 0.3s;
  margin-top: 12px;
}

.login-button:hover {
  background-color: #74b9ff;
}
