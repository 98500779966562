.card-container {
  /* width: 300px; */
  height: 115px;
  border-radius: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 20px;
}
.card-container h3 {
  font-size: 22px;
}

.card-context {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

.card-context h4 {
  font-size: 20px;
}
