.findALine__container {
  display: flex;
  /* background-color: red; */
}

.findALine__data {
  /* background-color: red; */
}

.findALine__data h1 {
  margin-bottom: 30px;
}

.findALine__smallscreen__filter {
  background-color: #f6f6f6;
  /* display: flex; */
  /* align-items: center; */
  height: 50px;
  margin-bottom: 40px;
  padding: 10px;
  display: none;
}
.findALine__smallscreen__filter_context {
  /* background-color: red; */
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 80px;
}

.findALine__smallscreen__filter span {
  margin-left: 10px;
}

/* SearchBar CSS */

.search-form {
  /* position: relative; */
  /* display: flex; */
  /* top: 50%;
  left: 50%; */
  display: flex;
  align-items: center;
  width: 72vw;
  height: 50px;
  border-radius: 5px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  /* transform: translate(-50%, -50%); */
  background: #fff;
  margin-bottom: 20px;
  padding: 10px;
}

.search-form.focus {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.15);
}

.search-input {
  /* position: absolute; */
  display: flex;
  align-items: center;
  top: 10px;
  left: 38px;
  font-size: 14px;
  background: none;
  color: #5a6674;
  width: 195px;
  height: 20px;
  border: none;
  appearance: none;
  outline: none;
  margin-left: 10px;
}

.search-input::-webkit-search-cancel-button {
  appearance: none;
}

.search-button {
  position: absolute;
  top: 10px;
  left: 15px;
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  outline: none !important;
  cursor: pointer;
}

.search-button svg {
  width: 20px;
  height: 20px;
  fill: #5a6674;
}

.search-option {
  position: absolute;
  text-align: right;
  top: 10px;
  right: 15px;
}

.search-option div {
  position: relative;
  display: inline-block;
  margin: 0 1px;
  cursor: pointer;
}

.search-option div input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.01;
  cursor: pointer;
}

.search-option div span {
  position: absolute;
  display: block;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  background: #929aa3;
  color: #fff;
  font-size: 9px;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  padding: 4px 7px;
  border-radius: 12px;
  top: -18px;
  transition: all 0.2s ease-in-out;
}

.search-option div span::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  border-top: 4px solid #929aa3;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  transition: all 0.2s ease-in-out;
}

.search-option div:hover span {
  opacity: 1;
  top: -21px;
}

.search-option div label {
  display: block;
  cursor: pointer;
}

.search-option div svg {
  height: 20px;
  width: 20px;
  fill: #5a6674;
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.search-option div:hover svg {
  opacity: 1;
}

.search-option div input:checked + label svg {
  fill: white;
  opacity: 0.9;
}

.search-option div input:checked + label span {
  background: white;
}

.search-option div input:checked + label span::after {
  border-top-color: white;
}

/* Card CSS */
.findALine__card {
  /* background: red; */
  width: 320px;
}

/* Screen Sizes */
@media (max-width: 1370px) {
  .findALine__smallscreen__filter {
    display: block;
  }
}
