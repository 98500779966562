/* FilterComponent CSS */
.filter__container {
  width: 350px;
  height: 300px;
  /* border-right-style: solid;
  border-right-width: 2px;
  border-right-color: lightgray; */
  /* flex-direction: column; */
  /* background-color: blue; */
}
.filter__context {
  margin-top: 30px;
}

.filter__context h4 {
  font-weight: bold;
  font-size: 18px;
}

@media (max-width: 1370px) {
  .filter__container {
    display: none;
  }
}

/* TraitChartModal Component CSS */
.popup-container {
  text-align: center;
  margin-top: 50px;
}

/* .popup-container {
  display: none;
  text-align: center;
  margin-top: 50px;
}

.popup-container.open {
  display: block;
} */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #fff;
  width: 50vw;
  height: 80vh;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: scroll;
}

.popup-header-context {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.popup-header-context span {
  cursor: pointer;
}

.popup-content h2 {
  margin-bottom: 10px;
}

.popup-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  /* background-color: red; */
}

.popup-description span {
  font-family: sans-serif;
  text-align: left;
}
